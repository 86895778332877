import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Switch, Button, Modal, Form, Select, Input, message,Typography,Divider,Row,Col,Tooltip } from 'antd';
import Evaluatoredit from './Evaluatoredit';
import { CopyOutlined,DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Title, Text } = Typography;

const { Option } = Select;

const TestEvaluatee = () => {
    const [companies, setCompanies] = useState([]);
    const [uniqueCompanyNames, setUniqueCompanyNames] = useState([]);
    const [evaluatees, setEvaluatees] = useState([]);
    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedCompanyID, setSelectedCompanyID] = useState('');
    const [selectedEvaluatee, setSelectedEvaluatee] = useState(null);
    const [evaluateeDetails, setEvaluateeDetails] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [availableQuestions, setAvailableQuestions] = useState([]);
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [language, setLanguage] = useState('English');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [url,setUrl] = useState('');


  

    useEffect(() => {
        fetchCompanies();
    }, []);

    useEffect(() => {
        if (selectedCompanyID) {
            fetchEvaluatees(selectedCompanyID, selectedYear);
        }
    }, [selectedCompanyID, selectedYear]);

    useEffect(() => {
        if (selectedEvaluatee) {
            fetchEvaluateeDetails();
        }
    }, [selectedEvaluatee, language]);

    useEffect(() => {
        if (isModalVisible) {
            fetchAvailableQuestions(questions);
        }
    }, [isModalVisible, language]);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get('https://backend.idacds.systems/companiesi', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setCompanies(response.data);

            const uniqueNames = [...new Set(response.data.map(item => item.CompanyName))];
            setUniqueCompanyNames(uniqueNames);
            console.log('Fetched companies:', response.data);
            console.log('Unique company names:', uniqueNames);
        } catch (error) {
            message.error('Error fetching companies');
        }
    };

    const fetchEvaluatees = async (companyId) => {
        try {
            const response = await axios.get(`https://backend.idacds.systems/evaluatees/${companyId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setEvaluatees(response.data);
            console.log('Fetched evaluatees:', response.data);
        } catch (error) {
            message.error('Error fetching evaluatees');
        }
    };

    const fetchEvaluateeDetails = async () => {
        try {
            const response = await axios.get(`https://backend.idacds.systems/evaluatee/${selectedEvaluatee}/${language}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            const { evaluatee, questions: evaluateeQuestions } = response.data;
            setEvaluateeDetails(evaluatee);
            setQuestions(evaluateeQuestions);
            console.log('Fetched evaluatee details:', response.data);
            setUrl(`http://localhost:3000/form/${evaluatee.EvaluateeID}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
        } catch (error) {
            message.error('Error fetching evaluatee details');
        }
    };

    const fetchAvailableQuestions = async (currentQuestions = []) => {
        try {
            const url = language === 'Sinhala' ? 'https://backend.idacds.systems/questionlistsin' : 'https://backend.idacds.systems/questionlist';
            const response = await axios.get(url,{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            console.log('Current questions:', currentQuestions);
            console.log('Available questions from API:', response.data);

            setAvailableQuestions(response.data);

            const assignedQuestionIds = currentQuestions.map(q => q.QuestionID);
            console.log('Assigned question IDs:', assignedQuestionIds);

            const filtered = response.data.filter(q => !assignedQuestionIds.includes(q.id));
            console.log('Filtered questions:', filtered);

            setFilteredQuestions(filtered);
        } catch (error) {
            message.error('Error fetching available questions');
        }
    };

    const handleLanguageChange = (checked) => {
        setLanguage(checked ? 'Sinhala' : 'English');
        console.log('Language changed to:', checked ? 'Sinhala' : 'English');
    };

    const handleAdd = async (values) => {
        try {
            await axios.post(`https://backend.idacds.systems/evaluatee/${selectedEvaluatee}/question`, { questionId: values.questionId }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            message.success('Question added to evaluatee successfully');
            fetchEvaluateeDetails();
            setIsModalVisible(false);
            form.resetFields();
        } catch (error) {
            message.error('Error adding question to evaluatee');
        }
    };

    const handleRemove = async (questionId) => {
        try {
            await axios.delete(`https://backend.idacds.systems/evaluatee/${selectedEvaluatee}/question/${questionId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            message.success('Question removed from evaluatee successfully');
            fetchEvaluateeDetails();
        } catch (error) {
            message.error('Error removing question from evaluatee');
        }
    };

    const handleUpdateDetails = async () => {
        try {
            await axios.put(`https://backend.idacds.systems/evaluateeupdate/${selectedEvaluatee}`, {
                FirstName: evaluateeDetails.FirstName,
                LastName: evaluateeDetails.LastName,
                Designation: evaluateeDetails.Designation,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            message.success('Evaluatee details updated successfully');
        } catch (error) {
            message.error('Error updating evaluatee details');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEvaluateeDetails(prevDetails => ({
            ...prevDetails,
            [name]: value,
        }));
    };
    const copyToClipboard = () => {
        navigator.clipboard.writeText(url).then(() => {
            // Optionally handle successful copy, e.g., show a message
            console.log('URL copied to clipboard');
        }).catch((err) => {
            // Optionally handle copy failure
            console.error('Failed to copy URL to clipboard:', err);
        });
    };
    const handleCompanyNameChange = (value) => {
        setSelectedCompanyName(value);
        setSelectedYear('');
        setSelectedCompanyID('');
    };

    const handleYearChange = (value) => {
        setSelectedYear(value);
        const selectedCompany = companies.find(
            company => company.CompanyName === selectedCompanyName && company.CompanyYear === parseInt(value)
        );
        setSelectedCompanyID(selectedCompany ? selectedCompany.CompanyID : '');
    };

    const companyYears = selectedCompanyName 
        ? [...new Set(companies.filter(company => company.CompanyName === selectedCompanyName).map(company => company.CompanyYear))]
        : [];

        return (
            <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
                <Title strong>Nominee Management</Title>
                <Title level={5} style={{ marginTop: '-20px', marginBottom: '30px' }}>
                Modify Nominee Information and Added Questions
                </Title>
            
                    <Form layout="vertical">
                        <div >
                            <div style={{
                            marginBottom: '20px',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            borderRadius: '10px',
                            paddingLeft:'30px',
                            paddingRight:'30px',
                            paddingTop:'10px',
                            paddingBottom:'7px'
                            }}>
                             <Row gutter={16}>
                                <Col span={8}>
                                <Title level={5}>Company Name:</Title>
                                </Col>
                                <Col span={8}>
                                <Title level={5}>Year:</Title>
                                </Col>
                                <Col span={8}>
                                <Title level={5}>Nominee Name:</Title>
                                </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item>
                                            <Select
                                                onChange={handleCompanyNameChange}
                                                placeholder="Select a company"
                                                style={{
                                                    backgroundColor: '#F6F7FB',
                                                    borderRadius: '10px',
                                                    border: '1px solid #D9D9D9',
                                                    minHeight: '32px',
                                                    fontWeight: 'bold',
                                                    width: '100%',
                                                    }}
                                                >
                                                {uniqueCompanyNames.map(name => (
                                                    <Option key={name} value={name}>
                                                        {name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item >
                                            <Select
                                                onChange={handleYearChange}
                                                placeholder="Select a year"
                                                disabled={!selectedCompanyName}
                                                value={selectedYear}
                                                style={{
                                                    backgroundColor: '#F6F7FB',
                                                    borderRadius: '10px',
                                                    border: '1px solid #D9D9D9',
                                                    minHeight: '32px',
                                                    fontWeight: 'bold',
                                                    width: '100%',
                                                    }}
                                                >
                                                {companyYears.map(year => (
                                                    <Option key={year} value={year}>
                                                        {year}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item>
                                            <Select
                                                onChange={setSelectedEvaluatee}
                                                placeholder="Select a nominee"
                                                disabled={!selectedCompanyName || !selectedYear}
                                                style={{
                                                    backgroundColor: '#F6F7FB',
                                                    borderRadius: '10px',
                                                    border: '1px solid #D9D9D9',
                                                    minHeight: '32px',
                                                    fontWeight: 'bold',
                                                    width: '100%',
                                                    }}
                                            >
                                                {evaluatees.map(evaluatee => (
                                                    <Option key={evaluatee.EvaluateeID} value={evaluatee.EvaluateeID}>
                                                        {evaluatee.FirstName} {evaluatee.LastName}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
        
                            {evaluateeDetails && (
                                <div style={{
                            marginBottom: '20px',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            borderRadius: '10px',
                            paddingLeft:'30px',
                            paddingRight:'30px',
                            paddingTop:'30px',
                            paddingBottom:'7px'
                            }}>
                                <Row gutter={16}>
                                    <Col span={7}>
                                        <Form.Item label="First Name">
                                            <Input
                                                name="FirstName"
                                                value={evaluateeDetails.FirstName}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Last Name">
                                            <Input
                                                name="LastName"
                                                value={evaluateeDetails.LastName}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item label="Designation">
                                            <Input
                                                name="Designation"
                                                value={evaluateeDetails.Designation}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item label=" ">
                                            <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                                                <Button type="primary" onClick={handleUpdateDetails} style={{backgroundColor:'#3b7dde',width:'39.5%' }}>Update</Button>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div style={{ textAlign: 'center' }}>
    <Title 
        level={4} 
        style={{
            textAlign: 'center',
            display: 'inline-block',
            paddingRight: '10px',
            border: '3px dashed #f0f0f0',  // Added dotted border
            padding: '5px',
            borderRadius: '10px',  // Added padding inside the border
            backgroundColor: '#f7f5f5', 
            marginRight: '5px' // Added background color
        }}
    >
        URL: {`http://localhost:3000/form/${evaluateeDetails.EvaluateeID}`}
    </Title>
    <Tooltip title="Copy to clipboard">
        <Button 
            icon={<CopyOutlined />}
            onClick={copyToClipboard}
            style={{
                display: 'inline-block',
                margin: 'auto',
                marginTop: '10px',
            }}
        />
    </Tooltip>
</div>
                                </div>
                            )}
                        </div>
        
                        <div style={{
                            marginBottom: '20px',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            borderRadius: '10px',
                            paddingLeft:'30px',
                            paddingRight:'30px',
                            paddingTop:'30px',
                            paddingBottom:'7px'
                            }}>
                            <Text style={{ fontSize: '16px', marginRight: '10px' }}>English</Text>
                            <Switch
                                            checked={language === 'Sinhala'}
                                            onChange={handleLanguageChange}
                                            style={{ margin: '0px', backgroundColor:'#3B7DDE' }} // Add margin for spacing
                                        />
                            <Text style={{ fontSize: '16px', marginLeft: '10px' }}>Sinhala</Text> 
                             <Button onClick={() => setIsModalVisible(true)} disabled={!selectedEvaluatee}
                             style={
                                {
                                    marginBottom: '20px',
                                    display: 'inline-block',
                                    float: 'right',
                                    backgroundColor: '#3b7dde',
                                    color: 'white',
                                }
                             }>Add Question</Button>
                            <Table dataSource={questions} rowKey="QuestionID" 
                            components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}>
                                <Table.Column title="Question" dataIndex="QuestionText" />
                                <Table.Column title="Category" dataIndex="QuestionCategory" />
                                <Table.Column title="Language" dataIndex="QuestionLanuage" />
                                <Table.Column
                                    title="Action"
                                    render={(text, record) => (
                                        <Button onClick={() => handleRemove(record.QuestionID)} danger icon={<DeleteOutlined />}>Delete</Button>
                                    )}
                                />
                            </Table>
                            <Modal
                                title="Add Question"
                                visible={isModalVisible}
                                onCancel={() => setIsModalVisible(false)}
                                footer={null}
                            >
                                <Form form={form} onFinish={handleAdd}>
                                    <Form.Item name="questionId" label="Question" rules={[{ required: true }]}>
                                        <Select style={{ width: '100%' }}>
                                            {filteredQuestions.map(q => (
                                                <Option key={q.id} value={q.id}>
                                                    {q.text}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">Add</Button>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </div>
                    </Form>
                    
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                {selectedEvaluatee && (
                            <div style={{
                            marginBottom: '20px',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            borderRadius: '10px',
                            paddingLeft:'30px',
                            paddingRight:'30px',
                            paddingTop:'10px',
                            paddingBottom:'7px'
                            }}>
                                    <Evaluatoredit 
                                     selectedEvaluatee={selectedEvaluatee} />
                                     </div>
                                )}
                            </Col>
                        </Row>
                    </Col>
            </div>
        );
    }
        export default TestEvaluatee;